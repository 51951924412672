@font-face {
  font-family: 'Aldo the Apache';
  font-style: normal;
  src: local('Aldo the Apache'), url('App/assets/fonts/AldotheApache.ttf') format('truetype');
}

@font-face {
  font-family: 'Anime Ace';
  font-style: normal;
  src: local('Anime Ace'), url('App/assets/fonts/AnimeAce.ttf') format('truetype');
}

@font-face {
  font-family: 'Gosmick Sans';
  font-style: normal;
  src: local('Gosmick Sans'), url('App/assets/fonts/GosmickSans.ttf') format('truetype');
}

@font-face {
  font-family: 'Gosmick Sans';
  font-style: bold;
  src: local('Gosmick Sans Bold'), url('App/assets/fonts/GosmickSansBold.ttf') format('truetype');
}